import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import AudioPlayer from "../../../components/audio-player"
import Breadcrumb from "../../../components/breadcrumb"
import Constrain from "../../../components/constrain"
import Checkbox from "../../../components/checkbox"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import PaperStack from "../../../components/paper-stack"
import Poem from "../../../components/poem"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import StepsBox from "../../../components/steps-box"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"
import Trivia from "../../../components/trivia"
import Caption from "../../../components/caption"

const WorkshopKalenderPage = () => {
  const data = useStaticQuery(graphql`
    query WorkshopSeptemberPageQuery {
      materialien: file(
        relativePath: { eq: "workshops/kalender/september/materialien.jpg" }
      ) {
        ...largeImage
      }
      gedicht: file(relativePath: { eq: "gedicht-september.pdf" }) {
        publicURL
      }
      beispiel01: file(
        relativePath: { eq: "workshops/kalender/september/beispiel-1.jpg" }
      ) {
        ...largeImage
      }
      beispiel02: file(
        relativePath: { eq: "workshops/kalender/september/beispiel-2.jpg" }
      ) {
        ...largeImage
      }
      beispiel03: file(
        relativePath: { eq: "workshops/kalender/september/beispiel-3.jpg" }
      ) {
        ...largeImage
      }
      schwan1: file(
        relativePath: { eq: "workshops/kalender/september/schwan-01.jpg" }
      ) {
        ...largeImage
      }
      schwan2: file(
        relativePath: { eq: "workshops/kalender/september/schwan-02.jpg" }
      ) {
        ...largeImage
      }
      schwan3: file(
        relativePath: { eq: "workshops/kalender/september/schwan-03.jpg" }
      ) {
        ...largeImage
      }
      schwan4: file(
        relativePath: { eq: "workshops/kalender/september/schwan-04.jpg" }
      ) {
        ...largeImage
      }
      schwan5: file(
        relativePath: { eq: "workshops/kalender/september/schwan-05.jpg" }
      ) {
        ...largeImage
      }
      schwan6: file(
        relativePath: { eq: "workshops/kalender/september/schwan-06.jpg" }
      ) {
        ...largeImage
      }
      schwan7: file(
        relativePath: { eq: "workshops/kalender/september/schwan-07.jpg" }
      ) {
        ...largeImage
      }
      schwan8: file(
        relativePath: { eq: "workshops/kalender/september/schwan-08.jpg" }
      ) {
        ...largeImage
      }
      schwan9: file(
        relativePath: { eq: "workshops/kalender/september/schwan-09.jpg" }
      ) {
        ...largeImage
      }
      schwan10: file(
        relativePath: { eq: "workshops/kalender/september/schwan-10.jpg" }
      ) {
        ...largeImage
      }
      schwan11: file(
        relativePath: { eq: "workshops/kalender/september/schwan-11.jpg" }
      ) {
        ...largeImage
      }
      schwan12: file(
        relativePath: { eq: "workshops/kalender/september/schwan-12.jpg" }
      ) {
        ...largeImage
      }
      schwan13: file(
        relativePath: { eq: "workshops/kalender/september/schwan-13.jpg" }
      ) {
        ...largeImage
      }
      schwan14: file(
        relativePath: { eq: "workshops/kalender/september/schwan-14.jpg" }
      ) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/kalender.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="workshop-kalender-september">
      <Seo
        title="September"
        description="Der Sommer neigt sich langsam dem Ende zu. Wir genießen die letzten warmen Tage, während der Herbst immer näher rückt. Vermisst du in der nasskalten Jahreszeit auch manchmal die Wärme und Lebendigkeit des Sommers? Über dieses Gefühl hat auch Hölderlin in seinem Gedicht ›Hälfte des Lebens‹ geschrieben. Für den September basteln wir dazu Schwäne und setzen sie in eine schöne Spätsommer-Landschaft."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Wörter und Bilder",
              link: "/workshops/kalender",
            },
            {
              title: "September",
              link: "/workshops/kalender/september",
            },
          ]}
        />
        <Stack space={[24, 24, 32]}>
          <Stack>
            <PageTitle>September</PageTitle>
            <Paragraph dropcap={true}>
              Der Sommer neigt sich langsam dem Ende zu. Wir genießen die
              letzten warmen Tage, während der Herbst immer näher rückt.
              Vermisst du in der nasskalten Jahreszeit auch manchmal die Wärme
              und Lebendigkeit des Sommers? Über dieses Gefühl hat auch
              Hölderlin in seinem Gedicht ›Hälfte des Lebens‹ geschrieben. Für
              den September basteln wir dazu Schwäne und setzen sie in eine
              schöne Spätsommer-Landschaft.
            </Paragraph>
          </Stack>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Du brauchst...
              </Heading>
              <Stack space={4}>
                <Checkbox space={3} id="checklist-1">
                  <Text as="span" size={4}>
                    Hölderlins Gedicht ›Hälfte des Lebens‹. Du findest es in
                    deinem Bastelset{" "}
                    <TextLink href={data.gedicht.publicURL}>
                      oder hier auf der Seite
                    </TextLink>
                    .
                  </Text>
                </Checkbox>
                <Checkbox space={3} id="checklist-2">
                  <Text as="span" size={4}>
                    Buntes Origami-Papier
                  </Text>
                </Checkbox>
                <Checkbox space={3} id="checklist-3">
                  <Text as="span" size={4}>
                    Buntstifte
                  </Text>
                </Checkbox>
                <Checkbox space={3} id="checklist-4">
                  <Text as="span" size={4}>
                    Ein Blatt Papier
                  </Text>
                </Checkbox>
                <Checkbox space={3} id="checklist-5">
                  <Text as="span" size={4}>
                    Einen Klebestift
                  </Text>
                </Checkbox>
              </Stack>
              <Image
                image={data.materialien.childImageSharp.gatsbyImageData}
                alt="Die Anleitung zum Kalenderblatt mit den Buchstaben S,O,M,M,E,R zum Ausschneiden"
              />
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Lesen und Hören
              </Heading>
              <Paragraph>
                Höre dir das Gedicht an, oder lese es dir durch.
              </Paragraph>
              <AudioPlayer
                src={`workshops/kalender/september-haelfte-des-lebens`}
              />
              <PaperStack>
                <Stack space={6}>
                  <Heading as="div" level={5}>
                    Hälfte des Lebens
                  </Heading>
                  <Poem size={[3, 3, 4]}>
                    <p>Mit gelben Birnen hänget</p>
                    <p>Und voll mit wilden Rosen</p>
                    <p>Das Land in den See,</p>
                    <p>Ihr holden Schwäne,</p>
                    <p>Und trunken von Küssen</p>
                    <p>Tunkt ihr das Haupt</p>
                    <p>Ins heilignüchterne Wasser.</p>
                    <br />
                    <p>Weh mir, wo nehm’ ich, wenn</p>
                    <p>Es Winter ist, die Blumen, und wo</p>
                    <p>Den Sonnenschein,</p>
                    <p>Und Schatten der Erde?</p>
                    <p>Die Mauern stehn</p>
                    <p>Sprachlos und kalt, im Winde</p>
                    <p>Klirren die Fahnen. </p>
                  </Poem>
                </Stack>
              </PaperStack>
            </Stack>
          </Constrain>

          <Constrain>
            <Trivia>
              ›Hälfte des Lebens‹ ist eines der berühmtesten Gedichte von
              Friedrich Hölderlin. Er hat darin viele Details versteckt hat.
              Obwohl die beiden Strophen des Gedichts zwei völlig gegensätzliche
              Landschaften beschreiben, sind sie ganz ähnlich aufgebaut: Sie
              haben dieselbe Anzahl an Zeilen und bestehen auch aus beinahe
              genauso vielen Silben.
            </Trivia>
          </Constrain>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Deine Aufgabe Schritt für Schritt:
              </Heading>
              <Stack space={6}>
                <Stack space={6}>
                  <StepsBox step={1}>
                    <Paragraph>
                      Hast du die Schwäne im Gedicht entdeckt? Aus dem bunten
                      Origami-Papier wollen wir nun Schwäne falten, die du
                      später in deinen Kalender kleben kannst. Eine
                      Falt-Anleitung dazu findest du hier:
                    </Paragraph>
                  </StepsBox>
                  <Grid columns={[1, 2]} space={3} alignX="center">
                    <Image
                      image={data.schwan1.childImageSharp.gatsbyImageData}
                      alt="Schritt 1"
                    />
                    <Image
                      image={data.schwan2.childImageSharp.gatsbyImageData}
                      alt="Schritt 2"
                    />
                  </Grid>
                  <Caption>
                    Nimm dir ein quadratisches Blatt und falte die untere Ecke
                    einmal auf die obere oberen Ecke.
                  </Caption>
                  <Image
                    image={data.schwan3.childImageSharp.gatsbyImageData}
                    alt="Schritt 3"
                  />
                  <Caption>
                    Falte das Papier wieder auseinander und drehe es so, dass
                    die weiße Seite nach oben zeigt.
                  </Caption>
                  <Grid columns={[1, 2]} space={3} alignX="center">
                    <Image
                      image={data.schwan4.childImageSharp.gatsbyImageData}
                      alt="Schritt 4"
                    />
                    <Image
                      image={data.schwan5.childImageSharp.gatsbyImageData}
                      alt="Schritt 5"
                    />
                  </Grid>
                  <Caption>
                    Nun faltest du die rechte obere und die rechte untere Kante
                    auf den Falz in der Mitte.
                  </Caption>
                  <Image
                    image={data.schwan6.childImageSharp.gatsbyImageData}
                    alt="Schritt 6"
                  />
                  <Caption>
                    Dann wendest du das Blatt, sodass die farbige Seite oben
                    liegt.
                  </Caption>
                  <Grid columns={[1, 2]} space={3} alignX="center">
                    <Image
                      image={data.schwan7.childImageSharp.gatsbyImageData}
                      alt="Schritt 7"
                    />
                    <Image
                      image={data.schwan8.childImageSharp.gatsbyImageData}
                      alt="Schritt 8"
                    />
                  </Grid>
                  <Caption>
                    Als nächstes faltest du die beiden Außenkanten wieder auf
                    den Falz in der Mitte.
                  </Caption>
                  <Grid columns={[1, 2]} space={3} alignX="center">
                    <Image
                      image={data.schwan9.childImageSharp.gatsbyImageData}
                      alt="Schritt 9"
                    />
                    <Image
                      image={data.schwan10.childImageSharp.gatsbyImageData}
                      alt="Schritt 10"
                    />
                  </Grid>
                  <Caption>
                    Jetzt faltest du die untere Spitze auf die obere Spitze. Das
                    ist der Hals des Schwanes. Für seinen Schnabel knickst du
                    ein Stück der Spitze um.
                  </Caption>
                  <Grid columns={[1, 2]} space={3} alignX="center">
                    <Image
                      image={data.schwan11.childImageSharp.gatsbyImageData}
                      alt="Schritt 11"
                    />
                    <Image
                      image={data.schwan12.childImageSharp.gatsbyImageData}
                      alt="Schritt 12"
                    />
                  </Grid>
                  <Caption>
                    Richte den Hals des Schwanes auf. Dann klappst du die Flügel
                    nach links unten bzw. nach rechts unten.
                  </Caption>
                  <Image
                    image={data.schwan13.childImageSharp.gatsbyImageData}
                    alt="Schritt 13"
                  />
                  <Caption>
                    Der Schwan ist nun fast fertig. Du kannst ihn noch etwas
                    zurecht biegen und die oberen Federn nach unten biegen.
                  </Caption>
                  <Image
                    image={data.schwan14.childImageSharp.gatsbyImageData}
                    alt="Schritt 14"
                  />
                </Stack>
                <StepsBox step={2}>
                  <Paragraph>
                    Nachdem die Schwäne fertig gefaltet sind, brauchen sie
                    unbedingt noch eine Umgebung, in der sie sich wohlfühlen.
                    Inspirationen dafür findest du vielleicht in Hölderlins
                    Gedicht. Du kannst den Hintergrund entweder direkt in den
                    Kalender malen oder hierfür ein extra Blatt verwenden, das
                    du später in den Kalender hinein klebst. Deiner Kreativität
                    sind keine Grenzen gesetzt!
                  </Paragraph>
                </StepsBox>
                <StepsBox step={3}>
                  <Paragraph>
                    Zuletzt platzierst du deine Schwäne auf dem Hintergrundbild
                    und fixierst sie mit etwas Kleber.
                  </Paragraph>
                </StepsBox>
              </Stack>
            </Stack>
          </Constrain>

          <Stack space={6}>
            <Heading as="h2" level={4} align="center">
              So kann deine Kalenderseite aussehen:
            </Heading>
            <Grid columns={[1, 2]} space={3} alignX="center">
              <Image
                image={data.beispiel01.childImageSharp.gatsbyImageData}
                alt="Detailansicht der fertigen Kalenderseite. Bunte Buchstaben und ein handgeschriebenes Gedicht"
              />
              <Image
                image={data.beispiel02.childImageSharp.gatsbyImageData}
                alt="Ein weitere Detailansicht der fertigen Kalenderseite. Der Buchstabe E ist fokussiert"
              />
              <Image
                image={data.beispiel03.childImageSharp.gatsbyImageData}
                alt="Ein weitere Detailansicht der fertigen Kalenderseite. Der Buchstabe E ist fokussiert"
              />
            </Grid>
          </Stack>

          <Constrain>
            <Stack>
              <Paragraph>
                Wir freuen uns über ein Bild von deiner Kalenderseite! Lass dir
                von einem Erwachsenen helfen und schicke uns ein Foto an{" "}
                <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                  hoelderlinturm@tuebingen.de
                </TextLink>
              </Paragraph>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default WorkshopKalenderPage
